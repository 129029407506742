import { Company, HammrOrganization } from 'interfaces/company';
import { FullCalendarEvent } from 'interfaces/full-calendar';
import { HammrUser, User } from 'interfaces/user';

import { simpleHash } from 'utils/utils';
import { colorList } from 'utils/colors';

import UserIcon from 'components/icons/UserIcon';
import { PayFrequency, PaySchedule, WeekDay } from '../interfaces/payschedule';
import dayjs from 'dayjs';

/**
 * Transforms Hammr's user data from backend to what check-express expects
 * @param userData {HammrUser}
 * @returns User | boolean {User}
 */
export const transformUser = (userData: HammrUser): User | boolean => {
  const organizationId = userData?.organization?.id || userData?.organizationId;
  if (typeof userData === 'object') {
    const remappedUser = {
      checkCompanyId: userData?.organizationCheckCompanyId || '',
      createdAt: userData?.createdAt || new Date(),
      email: userData?.email || '',
      isCompanyAdmin: userData?.role === 'ADMIN' || false,
      name: `${userData.firstName} ${userData.lastName}`,
      companyId: `${organizationId}`,
      uid: `${userData.id}`,
      checkEmployeeId: userData?.checkEmployeeId,
      checkContractorId: userData?.checkContractorId,
      jobTitle: userData?.position || 'Worker',
      pay: `${userData?.hourlyRate}` || '0.00',
      avatarURL: '',
      signer_title: userData?.position || 'Worker',
      workerClassification: userData.workerClassification,
      phone: userData?.phone,
      isClockInReminderEnabled: userData?.isClockInReminderEnabled,
      isClockOutReminderEnabled: userData?.isClockOutReminderEnabled,
      clockInReminderAt: userData?.clockInReminderAt,
      clockOutReminderAt: userData?.clockOutReminderAt,
      role: userData?.role,
    };

    return remappedUser;
  }

  return false;
};

/**
 * Transforms Hammr's user data from backend to what check-express expects (from getHammrUser endpoint and not /verify-otp)
 * @param userData {HammrUser}
 * @returns User | boolean {User}
 */
export const transformGetHammrUsers = (userData: HammrUser): User | boolean => {
  const organizationId = userData?.organization?.id || userData?.organizationId;
  if (typeof userData === 'object') {
    const remappedUser = {
      checkCompanyId: userData?.organization?.checkCompanyId || '',
      createdAt: userData?.createdAt || new Date(),
      email: userData?.email || '',
      isCompanyAdmin: userData?.role === 'ADMIN' || false,
      name: `${userData.firstName} ${userData.lastName}`,
      companyId: `${organizationId}`,
      uid: `${userData.id}`,
      checkEmployeeId: userData?.checkEmployeeId,
      checkContractorId: userData?.checkContractorId,
      jobTitle: userData?.position || 'Worker',
      pay: `${userData?.hourlyRate}` || '0.00',
      avatarURL: '',
      signer_title: userData?.position || 'Worker',
      workerClassification: userData.workerClassification,
      phone: userData?.phone,
      isClockInReminderEnabled: userData?.isClockInReminderEnabled,
      isClockOutReminderEnabled: userData?.isClockOutReminderEnabled,
      clockInReminderAt: userData?.clockInReminderAt,
      clockOutReminderAt: userData?.clockOutReminderAt,
      role: userData?.role,
    };

    return remappedUser;
  }

  return false;
};

/**
 * Transforms Hammr's organization data from backend to what check-express expects
 * @param companyData {HammrOrganization}
 * @returns Company | boolean {Company}
 */
export const transformOrganization = (companyData: HammrOrganization): Company | boolean => {
  if (typeof companyData === 'object') {
    return {
      id: companyData.id,
      name: companyData.name,
      checkCompanyId: companyData?.checkCompanyId || '',
      slug: companyData?.slug || '',
      isCompanyRegistered: companyData.isRegisteredOnCheck,
      paySchedule: companyData.paySchedule ? transformPaySchedule(companyData.paySchedule) : undefined,
      isPayrollEnabled: companyData?.isPayrollEnabled || false,
      isSchedulingEnabled: companyData?.isSchedulingEnabled || false,
      isMessagingEnabled: companyData?.isMessagingEnabled || false,
      timezone: companyData?.timezone || 'America/Los_Angeles',
      timeTrackingSettings: companyData.timeTrackingSettings,
      overtimeSettings: companyData.overtimeSettings,
      prevailingWageSettings: companyData?.prevailingWageSettings,
    };
  }

  return false;
};

export function transformPaySchedule(data: Partial<Record<keyof PaySchedule, string>>): PaySchedule {
  return {
    payFrequency: data.payFrequency as PayFrequency,
    firstPayday: dayjs(data.firstPayday as string),
    secondPayday: data.secondPayday ? dayjs(data.secondPayday) : undefined,
    firstPeriodEnd: dayjs(data.firstPeriodEnd),
    checkPayScheduleId: data.checkPayScheduleId,
  };
}

/**
 * Essentially takes a collection of schedule events and flattens the users array into a collection of resources
 * @param collection {ScheduleEvent[]}
 * @returns - {Resource[]}
 */
export const denormalizeAndFlattenResourceData = (collection: any[]) => {
  const flattened = [];

  collection.forEach((event) => {
    // if the resourceType is users, then we want to flatten the users array
    event.users.forEach((resourceObj) => {
      const resource = {
        eventId: event.id,
        linkedEventId: event.linkedEventId || null,
        startTime: new Date(event.startTime),
        endTime: new Date(event.endTime),
        note: event.note,
        isPublished: event.isPublished,
        projectId: event.project.id,
        projectName: event.project.name,
        address: event.project.address,
        location: event.project.location,
        isGeofenced: event.project.isGeofenced,
        geofenceRadius: event.project.geofenceRadius,
        costCodeId: event.costCode?.id || null,
        costCodeName: event.costCode?.name || null,
      };

      resource['userId'] = resourceObj.id;
      resource['firstName'] = resourceObj.firstName;
      resource['lastName'] = resourceObj.lastName;
      resource['email'] = resourceObj.email;
      resource['position'] = resourceObj.position;
      resource['phone'] = resourceObj.phone;
      resource['fullName'] = `${resourceObj.firstName} ${resourceObj.lastName}`;
      resource['assigned'] = event.users;
      resource['notification'] = event.notification;

      flattened.push(resource);
    });
  });

  return flattened;
};

export const standardizeResourceData = (collection: any[]) => {
  return collection.map((resourceObj) => {
    return {
      eventId: resourceObj.id,
      linkedEventId: resourceObj.linkedEventId || null,
      startTime: new Date(resourceObj.startTime),
      endTime: new Date(resourceObj.endTime),
      note: resourceObj.note,
      isPublished: resourceObj.isPublished,
      projectId: resourceObj.project.id,
      projectName: resourceObj.project.name,
      address: resourceObj.project.address,
      location: resourceObj.project.location,
      isGeofenced: resourceObj.project.isGeofenced,
      geofenceRadius: resourceObj.project.geofenceRadius,
      costCodeId: resourceObj.costCode?.id || null,
      costCodeName: resourceObj.costCode?.name || null,
      assigned: resourceObj.users,
      notification: resourceObj.notification,
    };
  });
};

/**
 * Take a collection of resources with a property name to check for uniqueness; returns unique collection
 * @param resources {Resource[]} - collection of resources
 * @param propertyName {string} - property name to check for uniqueness
 * @returns - {Resource[]} - unique collection of resources
 */
export const uniqueResources = (resources: any[], propertyName: string) => {
  return resources.reduce((acc, current) => {
    const found = acc.find((res) => res[propertyName] === current[propertyName]);

    if (!found) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

/**
 * Formats the schedule resources for the calendar
 * @param uniqueResources {Resource[]} - see uniqueResources
 * @param resourceType {string} - {'users' | 'projects'} at the moment
 * @returns - {Resource[]}
 */
export const formatForCalendarResource = (uniqueResources: any[], resourceType: string) => {
  return uniqueResources.map((resource) => {
    if (resourceType === 'users') {
      return {
        id: resource.userId,
        title: resource.fullName,
        extendedProps: resource,
      };
    } else if (resourceType === 'projects') {
      return {
        id: resource.projectId,
        title: resource.projectName,
        extendedProps: resource,
      };
    }
  });
};

/**
 * Formats the schedule events for the calendar
 * @param denormalizedScheduleEvents {DenormalizedScheduleEvent[]} - see denormalizeAndFlattenResourceData
 * @param resourceType {string} - {'users' | 'projects'} at the moment
 * @returns {FullCalendarEvent[]}
 */
export const formatForCalendarEvent = (
  denormalizedScheduleEvents: any[],
  resourceType: string
): FullCalendarEvent[] => {
  return denormalizedScheduleEvents.map((event) => {
    const colorIndex =
      Math.abs(simpleHash(resourceType === 'users' ? event.projectName : event.fullName)) % colorList.length;
    return {
      // eventId is no longer unique. need composite
      id: `${event.eventId}-${event.userId}-${event.projectId}`,
      title: resourceType === 'users' ? event.projectName : event.fullName, // inverted because of the view
      resourceId: resourceType === 'users' ? event.userId : event.projectId,
      start: event.startTime,
      end: event.endTime,
      extendedProps: {
        ...event,
        eventColor: colorList[colorIndex],
      },
      backgroundColor: colorList[colorIndex],
    };
  });
};

export const formatProjectsForCalendarEvents = (normalizedScheduleEvents: any[]) => {
  return normalizedScheduleEvents.map((event) => {
    const colorIndex = Math.abs(simpleHash(event.projectName)) % colorList.length;

    return {
      id: `${event.eventId}-${event.projectId}`,
      title: `${event.assigned.length}`,
      resourceId: event.projectId,
      start: event.startTime,
      end: event.endTime,
      extendedProps: {
        ...event,
        icon: UserIcon,
        eventColor: colorList[colorIndex],
      },
      backgroundColor: colorList[colorIndex],
    };
  });
};

/**
 * Takes a string representation of a day of the week and returns the integer representation
 * @param dayOfWeekStr {string} - Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday
 * @returns - {number} - 0, 1, 2, 3, 4, 5, 6
 */
export const weekStartDateToInt = (dayOfWeekStr: string) => {
  switch (dayOfWeekStr.toLowerCase()) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    default:
      return 0;
  }
};
