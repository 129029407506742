'use client';

import * as React from 'react';
import { ReactNode } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { DialogTitle } from '@radix-ui/react-dialog';

import { cn } from '../lib/utils';
import CloseLine from '@hammr-icons/CloseLine';
import { cva } from 'class-variance-authority';
import s from './dialog.module.css';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogContent = DialogPrimitive.Content;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50',
      'bg-foreground/25',
      'data-[state=closed]:animate-fadeout data-[state=open]:animate-fadein',
      s.dialogOverlay,
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogSurfaceVariants = cva(cn(''), {
  variants: {
    size: {
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

const DialogSurface = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';
  }
>(({ className, size = 'md', children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] duration-200',
          'data-[state=closed]:scale-75 data-[state=open]:scale-100',
          'flex max-h-screen w-full flex-col overflow-hidden',
          'border border-soft-200',
          'rounded-20',
          'bg-background shadow-md',
          s.dialogSurface,
          dialogSurfaceVariants({ size }),
          className
        )}
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogOverlay>
  </DialogPortal>
));
DialogSurface.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  title,
  subtitle,
  icon,
  showCloseButton = true,
  direction = 'row',
  noBorder = false,
  className,
  rightContent,
  closeButtonClassName,
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: React.ReactNode;
  rightContent?: React.ReactNode;
  showCloseButton?: boolean;
  direction?: 'row' | 'column';
  noBorder?: boolean;
  className?: string;
  closeButtonClassName?: string;
}) => (
  <DialogPrimitive.Title asChild>
    <div
      className={cn(
        'relative flex items-start gap-3 px-5 py-4 pr-10',
        !subtitle && 'items-center',
        !noBorder && 'border-b border-soft-200',
        direction === 'row' ? 'flex-row' : 'flex-col items-center px-10 text-center',
        className
      )}
    >
      {icon}
      <div className="flex flex-1 flex-col gap-1">
        <DialogTitle className="text-sm font-medium text-strong-950">{title}</DialogTitle>
        {/*just so we don't get warnings in the console*/}
        <DialogPrimitive.Description className="hidden" />
        {!!subtitle && <div className="text-xs font-normal text-sub-600">{subtitle}</div>}
      </div>
      {rightContent}
      {showCloseButton && (
        <DialogPrimitive.Close
          className={cn('absolute right-4 top-4 p-0.5 text-sub-600 hover:text-strong-950', closeButtonClassName)}
        >
          <CloseLine className="h-5 w-5" />
        </DialogPrimitive.Close>
      )}
    </div>
  </DialogPrimitive.Title>
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('p-5', className)} {...props} />
);

DialogBody.displayName = 'DialogBody';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-row items-center justify-end space-x-2 border-t border-soft-200 px-5 py-4', className)}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogSurface,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogBody,
};
