import { AppProps } from 'next/app';

import { AuthProvider } from 'hooks/useAuth';
import { CompanyProvider } from 'hooks/useCompany';
import { AwsStsProvider } from 'hooks/useAwsSts';
import { AwsS3Provider } from 'hooks/useAwsS3';
import { DateSelectionProvider } from 'hooks/useDateSelection';
import 'css/tailwind.css';
import 'css/fonts.css';
import 'css/theme.css';
import 'css/fullcalendar.css';
import 'css/third-party.css';
import 'hammr-ui/components/calendar.css';
import 'hammr-ui/components/ag-grid.css';
import 'hammr-ui/components/sendbird.css';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

// TODO -> remove Toast provider from mui
import { Toaster } from '@/hammr-ui/components/toaster';
import { ConfirmDialogContainer } from '@/hammr-ui/components/ConfirmDialog/ConfirmDialogContainer';
import { logError, showErrorToast } from '@/utils/errorHandling';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      logError(error);
      showErrorToast(error);
    },
  }),
});

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CompanyProvider>
            <AwsStsProvider>
              <AwsS3Provider>
                <DateSelectionProvider>
                  <ConfirmDialogContainer />
                  <Component {...pageProps} />
                </DateSelectionProvider>
              </AwsS3Provider>
            </AwsStsProvider>
          </CompanyProvider>
        </AuthProvider>
        <Toaster />
      </QueryClientProvider>
    </>
  );
}
