import {
  useToast as useHammerToast,
  toast as hammerToast,
} from '@/hammr-ui/hooks/use-toast';

type ToastParams = {
  title?: string;
  description?: React.ReactNode;
  type: 'success' | 'warning' | 'error' | 'info';
};

export const useToast = () => {
  const { toast } = useHammerToast();

  const addToast = (toastObject: ToastParams) => {
    toast({
      title: toastObject.title,
      description: toastObject.description,
      duration: 6000,
      toastVariant: toastObject.type as
        | 'success'
        | 'warning'
        | 'error'
        | 'info',
    });
  };

  return {
    addToast,
  };
};

export const addToast = (toastObject: ToastParams) => {
  hammerToast({
    title: toastObject.title,
    description: toastObject.description,
    duration: 6000,
    toastVariant: toastObject.type,
  });
};
