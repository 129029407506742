import ConfirmDialog from './ConfirmDialog';
import { useConfirmDialogStore } from './store';

export const ConfirmDialogContainer = () => {
  const items = useConfirmDialogStore((state) => state.items);

  return (
    <>
      {items.map((item) => {
        const { id, isOpen, ...options } = item;

        return (
          <ConfirmDialog
            key={id}
            subtitle={options.subtitle}
            title={options.title}
            onConfirm={async () => options.onConfirm()}
            setOpen={options.onCancel}
            open={isOpen}
            confirmButton={options.confirmButton}
            confirmButtonText={options.confirmButtonText}
            icon={options.icon}
          />
        );
      })}
    </>
  );
};
