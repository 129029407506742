import { create } from 'zustand';
import { BasicDialogProps, ConfirmDialogOptions } from './types';

export type ConfirmDialogItemState = BasicDialogProps & ConfirmDialogOptions;

interface ConfirmDialogState {
  items: ConfirmDialogItemState[];
}

export const useConfirmDialogStore = create<ConfirmDialogState>(() => ({
  items: [],
}));
