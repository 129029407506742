import { ReactNode, forwardRef, type LegacyRef } from 'react';

type IconPathFunction = (props: React.SVGProps<SVGSVGElement>) => ReactNode;

export function createIcon(
  name: string,
  path: string | ReactNode | IconPathFunction
) {
  const Icon = forwardRef(
    (props: React.SVGProps<SVGSVGElement>, ref: LegacyRef<SVGSVGElement>) => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        {typeof path === 'string' ? (
          <path d={path} fill="currentColor" />
        ) : typeof path === 'function' ? (
          path(props)
        ) : (
          path
        )}
      </svg>
    )
  );

  Icon.displayName = name;

  return Icon;
}
